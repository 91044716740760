import Swup from 'swup';
import SwupHeadPlugin from '@swup/head-plugin';
import SwupJsPlugin from '@swup/js-plugin';
import SwupBodyClassPlugin from '@swup/body-class-plugin';
import SwupGaPlugin from '@swup/ga-plugin';
import SwupGtmPlugin from '@swup/gtm-plugin';

import jquery from "jquery";
window.$ = window.jQuery = jquery;

import 'bootstrap';

import {
    gsap
} from 'gsap';




const swup = new Swup({
    linkSelector: 'a[href^="' + window.location.origin + '"]:not([data-no-swup]), a[href^="/"]:not([data-no-swup]), a[href^="./"]:not([data-no-swup]), a[href^="#"]:not([data-no-swup])',
    plugins: [
        new SwupHeadPlugin(),
        new SwupBodyClassPlugin({
            prefix: 'page-'
        }),
        new SwupGaPlugin(),
        new SwupGtmPlugin(),
        new SwupJsPlugin([{
            from: '(.*)',
            to: '(.*)',
            in: (next) => {
                // document.querySelector('#swup').style.opacity = 1;

                window.scrollTo(0, 0);

                var tl = gsap.timeline();
                tl.from(".slide__businessman", {
                    duration: 0.5,
                    y: 1000,
                    ease: "back.out",
                })
                tl.from(".slide__number .number, .slide__title h1, .slide__desc p, .slide__upnext, .slide__buttons__container", {
                    duration: 0.8,
                    yPercent: 200,
                    stagger: 0.2,
                    ease: "back.out",
                }, "-=0.15")
                tl.from(".page-statistics .statistics", {
                    duration: 0.4,
                    xPercent: -100,
                    ease: "ease.in",
                })

                tl.from(".signup__title h2, .signup__intro p", {
                    duration: 0.8,
                    yPercent: 200,
                    stagger: 0.2,
                    ease: "back.in",
                })
                tl.from(".page-statistics .block", {
                    duration: 0.4,
                    opacity: 0,
                    y: 100,
                    stagger: 0.2,
                    ease: "ease.in",
                })
                tl.from(".page-statistics .form__item, .page-statistics .source", {
                    duration: 0.4,
                    opacity: 0,
                    y: 100,
                    stagger: 0.2,
                    ease: "ease.in",
                    onComplete: next,
                });
            },
            out: (next) => {
                // document.querySelector('#swup').style.opacity = 0;
                var tl = gsap.timeline();
                tl.to(".slide__businessman", {
                    duration: 0.5,
                    x: -1000,
                    ease: "back.in",
                })
                tl.to(".slide__number .number, .slide__title h1, .slide__desc p, .slide__upnext, .slide__buttons__container", {
                    duration: 0.8,
                    yPercent: 200,
                    stagger: 0.2,
                    ease: "back.in",
                    onComplete: next,
                }, "-=0.15")

            }
        }, ]),
    ],

});





function init() {
    if (document.querySelector('#slide01')) {
        // vanillaJS
        // document.getElementById('slide01').addEventListener('click', function () {
        //     document.body.classList.remove("test");
        //     document.body.classList.add("page-slide01");
        // });


        // $("#slide01").on('click', function () {
        //     $("body").removeClass("page-slide01 page-slide02 page-slide03 page-slide04 page-slide05 page-statistics");
        //     $("body").addClass("page-slide01");
        // });



    }

    if (document.querySelector('.page-statistics .ball')) {
        gsap.to(".page-statistics .ball", {
            duration: 0.6,
            y: -900,
            opacity: 0,
            ease: "back.in",
        });

    }


}



// run once 
init();

// this event runs for every page view after initial load
swup.on('contentReplaced', init);



function unload() {
    // if (document.querySelector('#slide01')) {
    //     slide01.destroy()
    // }


}

swup.on('willReplaceContent', unload);






// Animation on website load
var tl = gsap.timeline();
tl.from(".slide__businessman", {
    duration: 0.5,
    y: 1000,
    ease: "back.out",
})
tl.from(".slide__number .number, .slide__title h1, .slide__desc p, .slide__upnext, .slide__buttons__container", {
    duration: 0.8,
    yPercent: 200,
    stagger: 0.2,
    ease: "back.out",
}, "-=0.15")


tl.to(".ball__01", {
        duration: 7,
        x: 60,
        y: -20,
        repeat: -1,
        yoyo: true,
        ease: "Power1.easeInOut",

    },
    0)

tl.to(".ball__02", {
        duration: 6,
        x: 40,
        y: 20,
        repeat: -1,
        yoyo: true,
        ease: "Power1.easeInOut",

    },
    0)
tl.to(".ball__03", {
        duration: 5,
        x: -30,
        y: -20,
        repeat: -1,
        yoyo: true,
        ease: "Power1.easeInOut",

    },
    0)
tl.to(".ball__04", {
        duration: 6,
        x: -20,
        y: 40,
        repeat: -1,
        yoyo: true,
        ease: "Power1.easeInOut",

    },
    0)
tl.to(".ball__05", {
        duration: 5,
        x: 40,
        y: 20,
        repeat: -1,
        yoyo: true,
        ease: "Power1.easeInOut",

    },
    0);